import { api_to } from './api'
//Components
import DeleteDialog from '@/common/DeleteDialog.vue'
import ImageCropper from '@/common/Dialogs/ImageCropper.vue'
import CustomPhoneInput from '@/common/CustomPhoneInput.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'Company',
    components: {
        DeleteDialog,
        ImageCropper,
        CustomPhoneInput
    },
    data: () => ({
        paths: [
            { text: 'Settings', disabled: false, route: { name: 'settings' } },
            { text: 'Company', disabled: true, route: null }
        ],
        isValid: false,
        search: '',
        open_add_picture: false,
        defaultCountryCode: null,
        btnloading: false
    }),
    created() {
        this.$store.dispatch('configs/fetchSettings', this.user.company.id)
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },
    computed: {
        ...mapGetters(['user']),
        ...mapGetters('configs', ['fetching']),
        can_update() {
            return !!(this.user.is_company_owner || this.user.is_admin)
        },
        company_logo: {
            get() {
                return this.$store.getters['configs/logo']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'logo',
                    value: val
                })
            }
        },
        name: {
            get() {
                return this.$store.getters['configs/name']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'name',
                    value: val
                })
            }
        },
        short_description: {
            get() {
                return this.$store.getters['configs/short_description']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'short_description',
                    value: val
                })
            }
        },
        long_description: {
            get() {
                return this.$store.getters['configs/long_description']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'long_description',
                    value: val
                })
            }
        },
        email: {
            get() {
                return this.$store.getters['configs/email']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'email',
                    value: val
                })
            }
        },
        domain: {
            get() {
                return this.$store.getters['configs/domain']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'domain',
                    value: val
                })
            }
        },
        contact: {
            get() {
                return this.$store.getters['configs/contact']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'contact',
                    value: val
                })
            }
        },
        address: {
            get() {
                return this.$store.getters['configs/address']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'address',
                    value: val
                })
            }
        },
        sender_email: {
            get() {
                return this.$store.getters['configs/sender_email']
            },
            set(val) {
                this.$store.commit('configs/set_setting', {
                    key: 'sender_email',
                    value: val
                })
            }
        }
    },
    methods: {
        image_clicked() {
            this.open_add_picture = true
        },
        handleFileUpload(attachment) {
            api_to.upload_image(this.user.company_id, attachment).then(({ data }) => {
                this.$store.commit('set_user_company', data)
                this.company_logo = attachment.url_inline
                this.open_add_picture = false
                this.appSnackbar('Company logo updated')
            })
        },
        getCompanyDetails() {
            api_to.get_company_info(this.user.company_id).then(({ data }) => {
                this.company = data
                this.$store.commit('set_user_company', data)
            })
        },
        getFields() {
            return {
                name: this.$store.getters['configs/name'],
                short_description: this.$store.getters['configs/short_description'],
                long_description: this.$store.getters['configs/long_description'],
                domain: this.$store.getters['configs/domain'],
                address: this.$store.getters['configs/address'],
                contact: this.$store.getters['configs/contact'],
                email: this.$store.getters['configs/email'],
                sender_email: this.$store.getters['configs/sender_email']
            }
        },
        update_company() {
            if (!this.isValid) {
                this.appSnackbar('Please fill in required field *', 'error')
                return
            }
            this.btnloading = true
            api_to
                .update_company_profile(this.user.company_id, this.getFields())
                .then(({ data }) => {
                    this.appSnackbar('Company info updated.')
                })
                .finally(() => (this.btnloading = false))
        }
    }
}